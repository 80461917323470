import gql from 'graphql-tag'

export const CASE_CONSULTATION_FIELDS = gql`
  fragment case_consultation_fields on consultations {
    id
    created_at
    consultation_type_id
    consultation_type {
      display_name
    }
    overflow
    sending_vet_notes
    sending_vet_private_notes
    receiving_vet_notes
    receiving_vet_notes_parsed_predictions
    receiving_vet_private_notes
    s3_url
    stat_type_id
    status
    upgraded_at
    predictions {
      condition {
        display_name
      }
    }
    stat_type {
      id
      hours
    }
    case {
      id
    }
  }
`

export const CASE_FIELDS = gql`
  ${CASE_CONSULTATION_FIELDS}
  fragment case_fields on cases {
    id
    created_at
    additional_data
    dicom_server_study_instance_uid
    dicom_source
    s3_url

    dicom_server {
      organization_id
      organization {
        display_name
      }
    }

    consultations {
      ...case_consultation_fields
    }

    medical_images(where: { aws_s3_url: { _is_null: false } }, order_by: { view: { order_index: asc } }) {
      id
      ai_predictions_denormalized
      aws_s3_url
      created_at
      is_video
      type
      yolo_results_denormalized
      view {
        display_name
      }
      medical_image_permutations {
        created_at
        aws_s3_url
        confidence
        bounding_box
        ai_predictions_denormalized
        label
      }
      predictions_normalizeds(where: { environment: { _eq: "production" }, _and: { vet_id: { _is_null: true } } }) {
        id
        condition {
          display_name
        }
      }
    }

    patient {
      id
      age
      birthdate
      breed
      dicom_server_patient_id
      display_name
      gender
      neutered
      species

      is_equipment_patient_events: patient_events(
        where: { patient_event_type_id: { _eq: 3 }, additional_data: { _has_key: "is_equipment" } }
      ) {
        additional_data
      }
    }

    predictions_normalizeds(order_by: { id: asc }) {
      id
      display_name
      type
      condition {
        id
        display_name
        severity
      }
    }
  }
`

export const CASE_FIELDS_FULL = gql`
  ${CASE_CONSULTATION_FIELDS}
  fragment case_fields_full on cases {
    id
    created_at
    additional_data
    dicom_server_study_instance_uid
    dicom_source
    s3_url

    consultations {
      ...case_consultation_fields
    }

    dicom_server {
      organization {
        id
        enterprise {
          default_overflow
          overflow_rules {
            rule
          }
        }
        price_group {
          addon_type
          amount
          consultation_type_id
          is_exotic
          price_group_id
          software_charge_type_id
          specialist_amount
          modality {
            id
            display_name
          }
          addon {
            id
            display_name
            additional_data
          }
          stat_type {
            id
            hours
          }
        }
      }
    }

    medical_images(where: { aws_s3_url: { _is_null: false } }, order_by: { view: { order_index: asc } }) {
      id
      ai_predictions_denormalized
      aws_s3_url
      created_at
      dicom_server_instance_id
      type
      is_video
      medical_image_permutations {
        aws_s3_url
        confidence
        bounding_box
        ai_predictions_denormalized
        label
      }
      predictions_normalizeds(where: { environment: { _eq: "production" }, _and: { vet_id: { _is_null: true } } }) {
        id
        condition {
          display_name
        }
      }
      view {
        display_name
      }
    }

    patient {
      id
      age
      birthdate
      breed
      dicom_server_patient_id
      display_name
      gender
      neutered
      weight
      species
      cases(order_by: { created_at: desc }) {
        id
        additional_data
        created_at
        consultations {
          s3_url
        }
        medical_images {
          aws_s3_url
          type
        }
        medical_images_aggregate {
          aggregate {
            count
          }
        }
      }
    }

    predictions_normalizeds(order_by: { id: asc }) {
      id
      display_name
      type
      condition {
        id
        display_name
        severity
        category {
          display_name
        }
      }
    }
  }
`
