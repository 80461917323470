exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ai-task-tsx": () => import("./../../../src/pages/ai-task.tsx" /* webpackChunkName: "component---src-pages-ai-task-tsx" */),
  "component---src-pages-billing-tsx": () => import("./../../../src/pages/billing.tsx" /* webpackChunkName: "component---src-pages-billing-tsx" */),
  "component---src-pages-cases-tsx": () => import("./../../../src/pages/cases.tsx" /* webpackChunkName: "component---src-pages-cases-tsx" */),
  "component---src-pages-condition-tsx": () => import("./../../../src/pages/condition.tsx" /* webpackChunkName: "component---src-pages-condition-tsx" */),
  "component---src-pages-conditions-tsx": () => import("./../../../src/pages/conditions.tsx" /* webpackChunkName: "component---src-pages-conditions-tsx" */),
  "component---src-pages-consultation-tsx": () => import("./../../../src/pages/consultation.tsx" /* webpackChunkName: "component---src-pages-consultation-tsx" */),
  "component---src-pages-consultations-tsx": () => import("./../../../src/pages/consultations.tsx" /* webpackChunkName: "component---src-pages-consultations-tsx" */),
  "component---src-pages-dicom-servers-tsx": () => import("./../../../src/pages/dicom-servers.tsx" /* webpackChunkName: "component---src-pages-dicom-servers-tsx" */),
  "component---src-pages-earnings-tsx": () => import("./../../../src/pages/earnings.tsx" /* webpackChunkName: "component---src-pages-earnings-tsx" */),
  "component---src-pages-enterprise-tsx": () => import("./../../../src/pages/enterprise.tsx" /* webpackChunkName: "component---src-pages-enterprise-tsx" */),
  "component---src-pages-enterprises-tsx": () => import("./../../../src/pages/enterprises.tsx" /* webpackChunkName: "component---src-pages-enterprises-tsx" */),
  "component---src-pages-feed-tsx": () => import("./../../../src/pages/feed.tsx" /* webpackChunkName: "component---src-pages-feed-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-invoices-tsx": () => import("./../../../src/pages/invoices.tsx" /* webpackChunkName: "component---src-pages-invoices-tsx" */),
  "component---src-pages-metrics-tsx": () => import("./../../../src/pages/metrics.tsx" /* webpackChunkName: "component---src-pages-metrics-tsx" */),
  "component---src-pages-practices-tsx": () => import("./../../../src/pages/practices.tsx" /* webpackChunkName: "component---src-pages-practices-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-referral-tsx": () => import("./../../../src/pages/referral.tsx" /* webpackChunkName: "component---src-pages-referral-tsx" */),
  "component---src-pages-request-consult-tsx": () => import("./../../../src/pages/request-consult.tsx" /* webpackChunkName: "component---src-pages-request-consult-tsx" */),
  "component---src-pages-sales-tsx": () => import("./../../../src/pages/sales.tsx" /* webpackChunkName: "component---src-pages-sales-tsx" */),
  "component---src-pages-settings-tsx": () => import("./../../../src/pages/settings.tsx" /* webpackChunkName: "component---src-pages-settings-tsx" */),
  "component---src-pages-staff-tsx": () => import("./../../../src/pages/staff.tsx" /* webpackChunkName: "component---src-pages-staff-tsx" */),
  "component---src-pages-tagging-binary-tsx": () => import("./../../../src/pages/tagging/binary.tsx" /* webpackChunkName: "component---src-pages-tagging-binary-tsx" */),
  "component---src-pages-tagging-bounding-box-canvas-tsx": () => import("./../../../src/pages/tagging/BoundingBoxCanvas.tsx" /* webpackChunkName: "component---src-pages-tagging-bounding-box-canvas-tsx" */),
  "component---src-pages-tagging-feedback-tsx": () => import("./../../../src/pages/tagging/feedback.tsx" /* webpackChunkName: "component---src-pages-tagging-feedback-tsx" */),
  "component---src-pages-tagging-key-values-form-tsx": () => import("./../../../src/pages/tagging/keyValuesForm.tsx" /* webpackChunkName: "component---src-pages-tagging-key-values-form-tsx" */),
  "component---src-pages-tagging-metrics-tsx": () => import("./../../../src/pages/tagging/metrics.tsx" /* webpackChunkName: "component---src-pages-tagging-metrics-tsx" */),
  "component---src-pages-tagging-scale-tsx": () => import("./../../../src/pages/tagging/scale.tsx" /* webpackChunkName: "component---src-pages-tagging-scale-tsx" */),
  "component---src-pages-temporary-disruption-tsx": () => import("./../../../src/pages/temporary-disruption.tsx" /* webpackChunkName: "component---src-pages-temporary-disruption-tsx" */)
}

