import extend from 'lodash/extend'
import moment from 'moment'
import { createSlice } from '@reduxjs/toolkit'
import { navigate } from 'gatsby'

import CONFIG from '../../config'
import callFlask from '../callFlask'
import callHasura from '../callHasura'
import { AiMode } from '../../interfaces/AiMode'
import { NotificationId, setNotificationAction } from './notifications'
import { QueryName } from '../queryNames'
import { RequestedExoticsCount } from '../graphQlQueries/types/RequestedExoticsCount'
import { Role } from '../../interfaces/Role'
import { UserInfo } from '../graphQlQueries/types/UserInfo'
import { User_users, User_users_user_roles } from '../graphQlQueries/types/User'
import { VetsExpanded_users } from '../graphQlQueries/types/VetsExpanded'
import { Vets_users } from '../graphQlQueries/types/Vets'
import { defaultSetLoading, defaultNetworkingFailure, defaultNetworkingSuccess } from './common'
import { fetchRequestedConsultationsAction } from './consultations'
import { requestedExoticsCountQuery } from '../graphQlQueries/Consultation'

import {
  enterpriseIdsForUser,
  getWithExpiry,
  parseRole,
  setIsLoggedInCookie,
  setWithExpiry,
  trackHotjarEvent,
} from '../../lib/helpers'

import {
  fetchUserInfoQuery,
  fetchUserQuery,
  fetchVetsExpandedQuery,
  fetchVetsQuery,
  updateUserAudioAlertsOnQuery,
  updateUserDarkModeOnQuery,
  updateUserDisplayNameQuery,
  updateVetActiveQuery,
} from '../graphQlQueries/User'

export interface StatBooking {
  email: string
  start: string
  end: string
}

export interface VetStatus {
  available_for_exotics?: boolean
  stat_bonus_activated?: boolean
  holiday_bonus_activated?: boolean
  locked_consultations: { vet_id: string; consultation_id: number }[]
  my_locked_consultation_ids: number[]
  my_stat_requests: number[]
  notify_stat_ids: string[]
  online_vet_ids: string[]
  stat_available: boolean
  stat_plus_vet_ids: string[]
  no_long_stat_user_ids: string[]
}

export interface UsersState {
  accessToken: string
  aiMode: AiMode
  billingEnabled?: boolean
  defaultTurnaroundTime: number
  displayReferralModal: boolean
  exitedEnableBillingModal: boolean
  isQuerying: any
  isRadimalEnterprise: boolean
  navOpen: boolean
  role: Role
  statBookings?: StatBooking[]
  user?: User_users
  userInfo?: UserInfo
  vetStatus?: VetStatus
  vetStatusesLoading: boolean
  vets: Vets_users[]
  vetsExpanded: VetsExpanded_users[]
}

const statBonusActive = () => {
  const momentEst = moment(new Date(new Date().toLocaleString('en-US', { timeZone: 'America/New_York' })))
  const day = momentEst.day()
  const hour = momentEst.hour()
  // weekends or afer 5pm on fridays
  return [0, 6].includes(day) || (day === 5 && hour >= 17)
}

const holidayBonusActive = () => {
  // Dec 20 - Jan3
  return moment().dayOfYear() < 3 || moment().dayOfYear() > 353
}

const initialState: UsersState = {
  accessToken: '',
  aiMode: AiMode.Default,
  defaultTurnaroundTime: 48,
  displayReferralModal: false,
  exitedEnableBillingModal: true,
  isQuerying: {},
  isRadimalEnterprise: true,
  navOpen: false,
  role: Role.User,
  vetStatusesLoading: false,
  vets: [],
  vetsExpanded: [],
}

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setLoading: defaultSetLoading,
    networkingFailure: defaultNetworkingFailure,
    networkingSuccess: defaultNetworkingSuccess,

    displayEnableBillingModal: (state) => {
      state.exitedEnableBillingModal = false
    },

    exitedEnableBillingModal: (state) => {
      state.exitedEnableBillingModal = true
    },

    notLoggedIn: () => {
      const currentPage = window.location.pathname + window.location.search
      setWithExpiry('currentPage', currentPage, 60)
      navigate('/')
    },

    fetchVetsSuccess: (state, { payload }: { payload: Vets_users[] }) => {
      state.vets = payload
    },

    fetchVetStatuses: (state) => {
      state.vetStatusesLoading = true
    },

    fetchVetStatusesSuccess: (state, { payload }: { payload: VetStatus }) => {
      state.vetStatus = payload
      state.vetStatusesLoading = false
    },

    fetchVetStatusesFailure: (state) => {
      state.vetStatusesLoading = false
    },

    setAccessToken: (state, { payload }) => {
      state.accessToken = payload
    },

    fetchVetsExpandedSuccess: (state, { payload }: { payload: VetsExpanded_users[] }) => {
      state.vetsExpanded = payload
    },

    fetchUserSuccess: (state, { payload }: { payload: { user: User_users; billingEnabled: boolean; role: Role; aiMode: AiMode } }) => {
      state.user = payload.user
      state.role = payload.role
      state.aiMode = payload.aiMode
      const enterpriseId = payload.user.organization.enterprise.id
      const isRadimalEnterprise = enterpriseId === CONFIG.RADIMAL_ENTERPRISE_ID
      state.isRadimalEnterprise = isRadimalEnterprise
      state.defaultTurnaroundTime = payload.user.organization.enterprise.default_turnaround_hours
      // temporarily fake billing for non-Radimal enterprise users
      state.billingEnabled = payload.billingEnabled || !isRadimalEnterprise
      const redirect = getWithExpiry('currentPage')
      if (redirect) navigate(redirect)
      setIsLoggedInCookie()
    },

    updateVetStatCalendarSuccess: (state, { payload }: { payload: StatBooking[] }) => {
      state.statBookings = payload
    },

    setDisplayReferralModal: (state, { payload }: { payload: boolean }) => {
      state.displayReferralModal = payload
    },

    setUserInfo: (state, { payload }: { payload: UserInfo }) => {
      state.userInfo = payload
    },

    setNavOpen: (state, { payload }: { payload: boolean }) => {
      state.navOpen = payload
    },
  },
})

export const {
  displayEnableBillingModal,
  exitedEnableBillingModal,
  notLoggedIn,
  setAccessToken,
  fetchVetStatuses,
  fetchVetStatusesFailure,
  fetchVetStatusesSuccess,
  fetchVetsSuccess,
  setLoading,
  fetchUserSuccess,
  setUserInfo,
  setNavOpen,
  fetchVetsExpandedSuccess,
  networkingFailure,
  networkingSuccess,
  setDisplayReferralModal,
  updateVetStatCalendarSuccess,
} = usersSlice.actions

export const usersSelector = (state: any) => state.users

export default usersSlice.reducer

export function handleVetLogOff() {
  const userId = getWithExpiry('vet-id')
  callFlask(`/radiologist`, 'POST', { userId, key: 'online', value: 'off' })
  callFlask(`/radiologist`, 'POST', { userId, key: 'stat', value: 'off' })
}

export function fetchUserAction(accessToken: string, id: any, shouldSetLoading = true) {
  return async (dispatch: any) => {
    if (shouldSetLoading) dispatch(setLoading())

    try {
      const users = await callHasura(accessToken, fetchUserQuery(id))
      const user: User_users = users[0]

      if (user) {
        const role = parseRole(accessToken)
        const subscriptionsCount = user.related_accounts?.stripe_profile?.subscriptions?.total_count || 0
        const billingEnabled = subscriptionsCount > 0
        const aiMode = user.organization.ai_mode?.display_name === 'ER' ? AiMode.ER : AiMode.Default
        dispatch(fetchUserSuccess({ user, role, billingEnabled, aiMode }))
      } else {
        dispatch(networkingFailure())
      }
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function fetchVetStatusesAction(accessToken: string, user: User_users, role: Role) {
  return async (dispatch: any) => {
    const queryName = QueryName.FetchVetStatuses
    dispatch(setLoading(queryName))

    try {
      const enterpriseIds = enterpriseIdsForUser(user)
      const result: VetStatus = await callFlask(`/radiologist?enterpriseIds=${enterpriseIds}&userId=${user.id}&role=${role}`)
      const requestedExoticsCount: RequestedExoticsCount = await callHasura(accessToken, requestedExoticsCountQuery())
      // TODO: - the following should be made enterprise-specific
      const maxRequestedExoticsThreshold = 3
      result.available_for_exotics =
        (requestedExoticsCount.consultations_aggregate.aggregate?.count || 0) < maxRequestedExoticsThreshold
      result.stat_bonus_activated = statBonusActive()
      result.holiday_bonus_activated = holidayBonusActive()
      dispatch(fetchVetStatusesSuccess(result))
      if (parseRole(accessToken) !== Role.User) await dispatch(fetchRequestedConsultationsAction(accessToken, user, result))
      dispatch(networkingSuccess(queryName))
    } catch (error) {
      dispatch(networkingFailure(queryName))
    }
  }
}

export function notifyUsersStatAction(ids: string[]) {
  return async (_: any) => callFlask(`/notify-stat?ids=${ids.join(',')}`)
}

export function updateNotifyUsersStatAction(userId: string) {
  return async (_: any) => callFlask(`/notify-stat`, 'POST', { userId })
}

export function sendStatOffAlertAction(user: User_users) {
  return async (dispatch: any) => {
    const msg = `*Last specialist ${user.id} logged off for ${user.organization.enterprise.short_name} with a STAT remaining*`
    dispatch(slackMessageAction('stat', msg))
  }
}

export function slackMessageAction(endpoint: string, message: string, data: any = {}) {
  return async (dispatch: any) => {
    try {
      await callFlask(`/slack-message`, 'POST', extend(data, { endpoint, message }))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function fetchVetsAction(accessToken: string, enterpriseId: number) {
  return async (dispatch: any) => {
    try {
      const vets: Vets_users[] = await callHasura(accessToken, fetchVetsQuery(enterpriseId))
      dispatch(fetchVetsSuccess(vets))
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function fetchVetsExpandedAction(accessToken: string, enterprise_id: number) {
  return async (dispatch: any) => {
    try {
      const vetsExpanded: VetsExpanded_users[] = await callHasura(accessToken, fetchVetsExpandedQuery(enterprise_id))
      dispatch(fetchVetsExpandedSuccess(vetsExpanded))
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function updateVetStatusAction(user: User_users, key: string, value: string, value2?: string) {
  return async (dispatch: any) => {
    dispatch(fetchVetStatuses())

    if (value2?.includes('lock')) trackHotjarEvent(`${value2}_consultation`)

    try {
      const enterpriseIds = enterpriseIdsForUser(user)
      const result = await callFlask(`/radiologist`, 'POST', { userId: user.id, key, value, value2, enterpriseIds })

      if (result.error) {
        dispatch(setNotificationAction(NotificationId.ConsultationLocked))
        dispatch(fetchVetStatusesFailure())
      } else {
        result.stat_bonus_activated = statBonusActive()
        result.holiday_bonus_activated = holidayBonusActive()
        dispatch(fetchVetStatusesSuccess(result))
      }
    } catch (error) {
      dispatch(fetchVetStatusesFailure())
    }
  }
}

export function updateUserAudioAlertsOnAction(accessToken: string, id: string, audioAlertsOn: boolean) {
  return async (dispatch: any) => {
    try {
      await callHasura(accessToken, updateUserAudioAlertsOnQuery(id, audioAlertsOn))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function updateUserDarkModeOnAction(accessToken: string, id: string, darkModeOn: boolean) {
  return async (dispatch: any) => {
    try {
      await callHasura(accessToken, updateUserDarkModeOnQuery(id, darkModeOn))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function updateUserDisplayNameAction(accessToken: string, id: string, displayName: string) {
  return async (dispatch: any) => {
    try {
      await callHasura(accessToken, updateUserDisplayNameQuery(id, displayName))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function setAccessTokenAction(accessToken: string) {
  return async (dispatch: any) => {
    dispatch(setAccessToken(accessToken))
  }
}

export function notLoggedInAction() {
  return async (dispatch: any) => {
    dispatch(notLoggedIn())
  }
}

export function exitedEnableBillingModalAction() {
  return async (dispatch: any) => {
    dispatch(exitedEnableBillingModal())
  }
}

export function displayEnableBillingModalAction() {
  return async (dispatch: any) => {
    dispatch(displayEnableBillingModal())
  }
}

export function updateUserRoleAction(user: User_users, userRole: User_users_user_roles) {
  return async (dispatch: any) => {
    try {
      const params = { id: user.id, role: userRole.role, enterpriseId: userRole.enterprise.id, organizationId: user.organization.id }
      await callFlask('/user/role', 'PUT', params)
      window.location.reload()
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function updateVetStatCalendarAction(email: string, name: string, user_id: string, start: string, end: string) {
  return async (dispatch: any) => {
    const queryName = QueryName.UpdateVetStatCalendar

    try {
      dispatch(setLoading(queryName))
      const result: StatBooking[] = await callFlask('/user/stat-calendar/event', 'POST', { email, name, user_id, start, end })
      dispatch(updateVetStatCalendarSuccess(result))
      dispatch(networkingSuccess(queryName))
    } catch (error) {
      dispatch(networkingFailure(queryName))
    }
  }
}

export function getVetStatCalendarAction() {
  return async (dispatch: any) => {
    try {
      dispatch(setLoading())
      const result: StatBooking[] = await callFlask('/user/stat-calendar', 'GET')
      dispatch(updateVetStatCalendarSuccess(result))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function deleteVetStatCalendarEventAction(id: string) {
  return async (dispatch: any) => {
    try {
      dispatch(setLoading())
      const result: StatBooking[] = await callFlask('/user/stat-calendar/event', 'DELETE', { id })
      dispatch(updateVetStatCalendarSuccess(result))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function sendReferralsAction(organizationName: string, emails: string[]) {
  return async (dispatch: any) => {
    const query = QueryName.SendReferrals

    try {
      dispatch(setLoading(query))
      await callFlask('/referral', 'POST', { organizationName, emails: emails.join() })
      dispatch(networkingSuccess(query))
    } catch (error) {
      dispatch(networkingFailure(query))
    }
  }
}

export function setDisplayReferralModalAction(displayReferralModal: boolean) {
  return async (dispatch: any) => {
    dispatch(setDisplayReferralModal(displayReferralModal))
  }
}

export function setNavOpenAction(navOpen: boolean) {
  return async (dispatch: any) => {
    dispatch(setNavOpen(navOpen))
  }
}

export function postSlackMessageAction(text: string) {
  return async (_: any) => {
    if (CONFIG.IS_DEVELOPMENT) {
      console.log(`<SLACK> ${text}`)
    } else {
      const slackEngineeringWebhookUrl = 'https://hooks.slack.com/services/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'
      fetch(slackEngineeringWebhookUrl, { method: 'POST', body: JSON.stringify({ text }) })
    }
  }
}

export function fetchUserInfoAction(accessToken: string, organizationId: number) {
  return async (dispatch: any) => {
    try {
      const userInfo: UserInfo = await callHasura(accessToken, fetchUserInfoQuery(organizationId))
      dispatch(setUserInfo(userInfo))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function updateVetActiveAction(accessToken: string, id: string, enterpriseId: number, active: boolean) {
  return async (dispatch: any) => {
    try {
      await callHasura(accessToken, updateVetActiveQuery(id, active))
      dispatch(fetchVetsExpandedAction(accessToken, enterpriseId))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}
